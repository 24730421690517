import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
 headlineContainer: {
  textAlign: 'center',
  paddingLeft: 30,
  paddingRight: 30,
  minHeight: '400px',
 },
 smallTitle: {
  fontWeight: 'bold',
  marginBottom: '8px',
  fontSize: '14px',
  color: '#FFF',
  textShadow: '0px 3px 6px #00000029',
  textTransform: 'uppercase',
  fontFamily: 'Lato',
 },
 container: {
  display: 'grid',
  gridTemplateColumns: 'repeat( auto-fit, minmax(300px, 300px) )',
  justifyContent: 'center',
  padding: 24,
 },
 card: {
  backgroundColor: '#FFF',
  borderRadius: 12,
  height: 490,
  position: 'relative',
  width: 300,
  border: `2px solid ${theme.palette.primary.main}`,
  transition: 'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
 },
 cards_container: {
  margin: '50px 0',
  [theme.breakpoints.up('sm')]: {
   display: 'flex',
   justifyContent: 'center',
  },
 },
 cardMessage: {
  margin: '50px 10px',
  display: 'flex',
  padding: '40px',
  textAlign: 'center',
  borderBottom: `8px solid ${theme.palette.primary.main}`,
  borderRadius: ' 0px',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
   width: '400px',
  },
 },
 card_description: {
  fontSize: '12px',
  lineHeight: '24px',
 },
 ribbonContainer: {
  position: 'absolute',
  flexDirection: 'column',
  width: 'max-content',
  direction: 'rtl',
  right: '-0.5rem',
  top: 0,
 },
 ribbon: {
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: '3px 0 0 3px',
  boxShadow: theme.shadows[1],
  color: theme.palette.primary.main,
  display: 'flex',
  fontWeight: 600,
  height: 20,
  letterSpacing: 0.22,
  lineHeight: 1,
  marginTop: '1rem',
  padding: 4,
  position: 'relative',
  width: 'max-content',
  '&:after': {
   content: '""',
   borderBottom: '0.5rem solid #bfbfbf',
   borderRight: '0.5rem solid transparent',
   top: '-0.5rem',
   right: 0,
   position: 'absolute',
  },
  '&:not(:first-child)': {
  },
 },
 bannerImg: {
  maxWidth: '90%',
  alignSelf: 'center',
  backgroundSize: 'contain',
  backgroundPosition: 'center center',
  height: 90,
 },
 operationInfo: {
 },
 smallFlatButton: {
  background: '#32A65B 0% 0% no-repeat padding-box!important',
  fontSize: '12px!important',
  height: '25px!important',
  transition: 'height 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  width: '110px!important',
  '&:hover': {
   background: '#2A904E 0% 0% no-repeat padding-box!important',
  },
  alignSelf: 'center',
  justifySelf: 'end',
  gridArea: 'addToCartButton',
 },
 smallFlatButtonDisabled: {
  background: '#EEEEEE 0% 0% no-repeat padding-box!important',
  '&:hover': {
   background: '#EEEEEE 0% 0% no-repeat padding-box!important',
  },
 },
 moreInfo: {
  gridArea: 'moreInfo',
 },
 maxAmount: {
  gridArea: 'maxAmount',
  fontSize: 11,
  color: '#707070',
 },
 inputAmount: {
  gridArea: 'inputAmount',
  transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
 },
 form: {
  display: 'grid',
  gridTemplateAreas: `
        "addToCartButton"
        "moreInfo"
    `,
  gridGap: 4,
  padding: '0 16px',
 },
 formStepInputAmount: {
  gridTemplateAreas: `
        "inputAmount addToCartButton"
        "maxAmount maxAmount"
    `,
 },
 dollarSign: {
  fontSize: 14,
  fontWeight: 500,
  left: 16,
  position: 'absolute',
  zIndex: 1,
 },
 inputField: {
  '& input': {
   background: 'white',
   border: 'unset',
   fontSize: 14,
   fontWeight: 500,
   paddingLeft: 8,
   textAlign: 'left',
   width: '100%',
   '&::placeholder': {
    color: '#C6C6C6',
   },
  },
 },
 cartConfirmation: {
  alignItems: 'center',
  background: '#d5eadb',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  flexDirection: 'column',
  height: 63,
  justifyContent: 'center',
  padding: 16,
  position: 'relative',
  '& svg': {
   background: '#fff',
   borderRadius: '100%',
  },
 },
 infoTooltip: {
  width: '16px !important',
  height: '16px !important',
  paddingBottom: '2px',
  verticalAlign: 'middle',
  color: 'rgba(0, 0, 0, 0.4)',
 },
}))