import React from 'react';
import { Typography, Box, Tooltip} from '@cumplo/design-system';
import InfoIcon from '@mui/icons-material/Info';
import { useStyles } from './styles';

const StatBox = ({ text, textTooltip }) => {
 const classes = useStyles();

 return(
  <>
   <Box 
    display="flex"
    alignItems="center"
    justifyContent="center"
    mb={1}
   >
    <Typography
     variant="body2"
    >
     {text[0]}
    </Typography>
    {
     textTooltip &&  <Tooltip title={(
      <div>
       <Box>
        {textTooltip}
       </Box>
      </div>
     )}
     enterTouchDelay={0}
     leaveTouchDelay={3000}
     >
      <InfoIcon className={classes.infoTooltip} />
     </Tooltip>
    }
   </Box>
   <Typography
    variant="body2Medium"
    textAlign="center"
   >
    {text[1]}
   </Typography>
  </>
 )};
  
export default StatBox;