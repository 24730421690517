import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
 card: {
  backgroundColor: '#FFF',
  borderRadius: 12,
  position: 'relative',
  width: 310,
  minHeight: 500,
  border: '1px solid #CFD6DD',
  transition: 'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
 },
 bannerImg: {
  alignSelf: 'center',
  backgroundSize: 'contain',
  backgroundPosition: 'center center',
  height: 34,
  maxWidth: "90%",
 },
 operationInfo: {
 },
 infoTooltip: {
  width: '16px!important',
  height: '16px!important',
  paddingLeft: "2px",
  verticalAlign: 'middle',
  color: '#555F6D',
 },
 divCenteredPadding: {
  paddingBottom: 10,
  textAlign: 'center',
 },
 divCentered: {
  textAlign: 'center',
 },
 scoreContainer: {
  borderBottom: "1px solid #CFD6DD",
  borderLeft: "1px solid #CFD6DD",
  borderRadius: "0px 12px 0px 0px",
  backgroundColor: "#E9ECEF",
  height: '48px',
  display: 'flex',
 },
 businessName: {
  fontWeight: 600,
  textAlign: 'left',
  paddingLeft: '32px',
  paddingRight: "8px",
  height: 35,
  display: "flex",
  alignItems: "center",
  textTransform: "uppercase",
 },
 amountTitle: {
  color: '#6A6A6A',
  textAlign: 'center',
 },
 amount: {
  textAlign: 'center',
  fontWeight: 600,
  color: '#1B242C',
 },
 chip: {
  backgroundColor: "#CDDDFF !important",
  maxWidth: '210px !important',
  "& .MuiChip-label": {
    "& p": {
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      textOverflow: 'ellipsis !important',
    },
   lineHeight: 2
  },
 },
 divider: {
  margin: '12px !important'
 },
 marginVertical: {
  marginTop: '12px !important',
  marginBottom: '12px !important'

 },
 paddingLeftTop: {
  paddingLeft: "32px !important",
  paddingTop: "16px !important"
 }
}))