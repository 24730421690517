import axios from "axios";
import { addfixedHeaders } from "../utils/addFixedHeaders";

const vitrinaRestClient = () => {
    const country =
        typeof window !== "undefined" ? window.environmentCountry : "";
    const api = axios.create({ baseURL: process.env.GATSBY_HOST_URL });
    // TODO temporalmente el filtro no se esta considerando, hasta despues de entregable
    const getOpportunities = (filterBy = "createdAt:desc") => {
        return api.get(`/operaciones/vitrina/${country}`, {
            headers: { ...addfixedHeaders() },
        });
    };

    return {
        getOpportunities,
    };
};

export default vitrinaRestClient;
