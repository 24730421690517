import React from "react";
import {
 Box,
 FormControl,
 Select,
 MenuItem,
 Typography,
 ThemeProvider,
} from "@cumplo/design-system";
import { useMediaQuery } from "@mui/material";

const Filter = ({ handleChange, value, items }) => {
 const md = useMediaQuery("(min-width:768px)");

 return (
  <ThemeProvider>
   <Box display="flex" justifyContent="flex-end">
    <Box 
     sx={{ width: md ? 270 : 174 }} 
     display={ md ? "flex" : "block" }
     alignItems="center"
    >
     <Typography variant="body2" mb={ md ? 0 : "12px" } mr="12px">
            Ordenar por
     </Typography>
     <FormControl sx={{ width: 174 }}>
      <Select value={value} onChange={handleChange}>
       {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
         {item.label}
        </MenuItem>
       ))}
      </Select>
     </FormControl>
    </Box>
   </Box>
  </ThemeProvider>
 );
};

export default Filter;
