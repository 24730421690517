import { useState } from "react";

import vitrinaRestClient from "../service/vitrina";

const useGetOpportunities = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allCompleted, setAllCompleted] = useState(false);
  const [opportunities, setOpportunities] = useState([]);

  const getOpportunities = async (filterBy = "") => {
    setIsLoading(true);
    try {
      const result = await vitrinaRestClient().getOpportunities(filterBy);

      setOpportunities(result.data.data.map(op => { return { ...op.attributes } }));
      setAllCompleted(result.data.data.every((fr => (fr.attributes.operacion.porcentaje_inversion) === 100)))

    } catch (_) {
      setOpportunities([]);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    opportunities,
    getOpportunities,
    allCompleted
  };
};

export default useGetOpportunities;
