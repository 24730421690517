/* eslint-disable no-nested-ternary */
import React, {useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { isLoggedMX } from 'src/utils/validations'
import { navigate } from 'gatsby'
import { useStyles } from './styles'
import OpportunityCard from './OpportunityCard';

const Oportunidades = ({ oportunidades, loading }) => {
 const classes = useStyles()

 useEffect(() => {
  if (process.env.GATSBY_REACT_APP_REGION === "es-MX" && !isLoggedMX()) {
   navigate("/");
  }
 });

 const MessageOpportunities = () => (
  <div className={classes.cards_container}>
   <Card className={classes.cardMessage}>
    <CardContent>
     <div className={classes.card_description}>
            En estos momentos el equipo Cumplo está trabajando para traer más
            opciones en las cuales puedas invertir.
     </div>
    </CardContent>
   </Card>
  </div>
 );

 return (
  <>
   {(loading === true) ? (
    <Container>
     <Grid
      style={{
       paddingBottom: "60px",
      }}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
     >
      <Grid item>
       <CircularProgress />
      </Grid>
     </Grid>
    </Container>
   ) : (
    <Container>
     <Grid
      style={{
       paddingBottom: "60px",
      }}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
     >
      {
       process.env.GATSBY_REACT_APP_REGION === 'es-MX' &&  <MessageOpportunities /> }

      { (process.env.GATSBY_REACT_APP_REGION === 'es-CL' 
       | process.env.GATSBY_REACT_APP_REGION === 'es-PE') &&  
              oportunidades?.map((data) => (
               <Grid 
                key={data?.operacion?.id} 
                xs={12} 
                md={6} 
                lg={4}
                xl={3} 
                item 
                className={classes.container}>
                <Grid
                 style={{
                  minWidth: 310,
                 }}
                 item
                 xs={1}
                >
                 <OpportunityCard
                  cardData={data}
                  activateProgressBar
                  activateSimulation
                 />
                </Grid>
               </Grid>
              ))
      }
     </Grid>
    </Container>
   )}
  </>
 );
};

export default Oportunidades;
